import { Col, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function Privacy() {
  return (
    <Layout>
      <SEO title="Privacy" />
      <Row className="container mx-auto text-wrap py-5">
        <Col>
          <h1>Privacy Policy</h1>
          <p>
            <strong>Effective date:</strong>
            {' '}
            4/16/2022
          </p>
          <h3>1. Introduction</h3>
          <p>
            These Privacy Policy (“Privacy Policy”) apply to the use of the
            website and products provided by (hereinafter also referred as “we”
            or “us”).
          </p>
          <p>
            This Privacy Policy applies and has effect in respect of all
            services and other products, software, made available by us, as well
            as any other online features relating to the website and its content
            (the “Service(s)”).
          </p>
          <p>
            If you have any questions or comments about this Privacy Policy,
            please contact us at
            {' '}
            <a href="mailto:walttycontact@gmail.com" className="text-info">
              walttycontact@gmail.com
            </a>
            .
          </p>
          <p>
            We are committed to protecting and respecting your privacy. The
            Privacy Policy explains the basis on which personal information we
            collect from you will be processed by us or on our behalf. Where we
            decide the purpose or means for which personal data you supply
            through these Services is processed, we are the “controller”. Where
            you decide the purpose or means for which personal data you supply
            through these Services is processed, you are the “controller”. We
            will comply with proper and applicable data protection laws,
            including the General Data Protection Regulation 2016/679.
          </p>
          <p>
            We encourage you to read this Privacy Policy carefully as it
            contains important information about the following:
          </p>
          <ul>
            <li>What information we may collect about you;</li>
            <li>How we will use the information we collect about you;</li>
            <li>Whether we will disclose your details to anyone else; and</li>
            <li>
              Your choices and rights regarding the personal information you
              have provided to us.
            </li>
          </ul>
          <p>
            The Services may contain links to services owned and operated by
            third parties. We may also use some third-parties software or
            products to provide you with the Service properly. If we do so and
            provide third -parties of any personal data you can be sure the
            transfer is legal and secured. These third-party services may have
            their own privacy policies and we recommend that you review them.
            They will govern the use of personal information that you submit or
            which is collected by cookies and other tracking technologies whilst
            using these services. We do not accept any responsibility or
            liability for the privacy practices of such third party services and
            your use of these is at your own risk.
          </p>
          <p>
            We may make changes to this Privacy Policy in the future. You should
            check this page from time to time to ensure you are aware of any
            changes. Where appropriate we may notify you of such changes.
          </p>
          <h3>2. Information we may collect about you</h3>
          <p>
            We collect and process the following information which may include
            your personal data.
          </p>
          <p>
            Your name, last name, email address, phone number, contact data,
            device’s ID, your user preferences as well as all the data
            (including personal data) you supply to us and information provided
            by you when using the Service or website.
          </p>
          <h3>3. Collecting, processing and using personal data</h3>
          <p>
            We only store and process your personal data when you have
            voluntarily supplied us with it such as by filling in a contact form
            or signing up to the Service. Your personal data will only be
            disclosed or otherwise transmitted if this is necessary to implement
            the contract, render our Services or you have given your prior
            consent.
          </p>
          <h3>
            4. Why we collect information about you - purpose of processing
          </h3>
          <p>
            We will use information about you for delivering our Services to you
            under the terms of use agreed between us. The processing of
            information in this way is necessary for us to provide you the
            Service properly and to ensure the features function properly so
            that you have the best Service possible.
          </p>
          <p />
          <h3>5. Cookies and Web Beacons</h3>
          <p>
            We use cookies to make the use of our website easier for you. These
            cookies are used to store information including visitors’
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users’
            experience by customizing our web page content based on visitors’
            browser type and/or other information.Cookies may be opt-out by you
            and in case you do not accept cookies, this may lead to a limitation
            of functionality.
          </p>
          <h3>6. Log files</h3>
          <p>
            During every access of our website user data is transmitted by the
            respective Internet browser and stored in protocol files, the
            so-called server log files. The datasets stored here may contain
            such data as date and time of access, name website, IP address,
            referrer URL (original URL from which you arrived at the website),
            the amount of data transmitted, product and version information of
            the browser used.
          </p>
          <p />
          <h3>7. Geo-location data</h3>
          <p>
            In case geo-location is opt-in by you, please be aware we may have
            access to your geo-location data as we collect and process IP
            address of all devices using our Services. Collecting and processing
            your geo-location data refers to mobile devices as well as
            computers.
          </p>
          <h3>8. Children personal data</h3>
          <p>
            We do not collect personal information from anyone under the age of
            13. If you notice we collect and process personal data of anyone
            under this age, please contact us. We encourage parents and legal
            guardians to monitor their children’s Internet usage and to help
            enforce this Policy by instructing their children never to provide
            personal information through the websites or Services.
          </p>
          <h3>9. Data sharing</h3>
          <p>
            We will share your information (including personal data) with third
            parties only in the ways that are described in this Privacy Policy.
          </p>
          <p>
            Personnel, suppliers or subcontractors: We keep your information
            confidential, but may disclose it to our personnel, suppliers or
            subcontractors insofar as it is reasonably necessary for the
            purposes set out in this Privacy Policy. However, this is on the
            basis that they do not make independent use of the information, and
            have agreed to safeguard it.
          </p>
          <p>
            Required by law: In addition, we may disclose your information to
            the extent that we are required to do so by law (which may include
            to government bodies and law enforcement agencies); in connection
            with any legal proceedings or prospective legal proceedings; and in
            order to establish, exercise or defend our legal rights.
          </p>
          <h3>10. Data transmission</h3>
          <p>
            Your data is transmitted safely by using encryption. We secure our
            website and other systems with technical and organizational measures
            against the loss, destruction, access, change or dissemination of
            your data by unauthorized persons.
          </p>
          <h3>11. Your rights</h3>
          <p>
            You have the following rights over the way your personal data are
            processed.
          </p>
          <p>
            To make a request, please let us know by sending an email to
            {' '}
            <a href="mailto:walttycontact@gmail.com" className="text-info">
              walttycontact@gmail.com
            </a>
            .
          </p>
          <p>
            a) You have the right to request a copy of the personal information
            we process about you and to have any inaccuracies corrected.
          </p>
          <p>
            b) You can ask for supplying, correcting or deleting personal
            information held about you.
          </p>
          <p>
            c) You can ask us to restrict, stop processing, or to delete your
            personal data.
          </p>
          <p>d) You can withdraw your consent for data processing.</p>
          <p>
            e) Obtain a copy of your personal data, which you can use with
            another service provider
          </p>
          <p>f) Make a complaint to a Supervisory Authority.</p>
          <h3>12. Changes to this privacy policy</h3>
          <p>
            This Privacy Policy may be updated from time to time. We encourage
            you to review this website for the latest information on our privacy
            practices. If there are any material changes to this Privacy Policy,
            you will be notified by posting a notice on the website prior to the
            change becoming effective. If you do not accept any changes made to
            this Privacy Policy, please discontinue the use of the website and
            the Services. This Privacy Policy has been created with the help of
            {' '}
            <a
              href="http://livechat.com/privacy-policy-generator/?ppg"
              target="_blank"
              rel="noreferrer"
              className="text-info"
            >
              Privacy Policy Generator
            </a>
            .
          </p>
        </Col>
      </Row>
    </Layout>
  );
}

export default Privacy;
